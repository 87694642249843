$accent-100: #FF5B65;
$accent-20: #F7DADB;
$black: #000000;
$blue-100: #141625;
$grey-100: #111115;
$grey-10: #EEEEEE;
$grey-20: #CCCCCC;
$grey-30: #ADADAD;
$grey-40: #999999;
$grey-5: #F2F2F2;
$grey-60: #666666;
$grey-90: #25222B;
$success-100: #1A8466;
$success-20: #DDEDE8;
$warning-100: #A71212;
$white: #ffffff;
