@use 'src/styles/breakpoint';
@use 'src/styles/color';
@use 'src/styles/fontweight';


.heading {
  font-size: 20px;
  padding: 0 20px 10px;
  margin: 0;
  cursor: pointer;
  font-weight: fontweight.$semi_bold;
  color: color.$black;
  @media (min-width: breakpoint.$md) {
    padding: 0 20px 10px 0;
    margin: 0;
    font-size: 30px;
    line-height: 40px;
  }
}


.list {
  display: grid;
  grid-template-columns: 1fr;
  padding: 0;
  margin: 0 -20px;
  gap: 10px;
  @media (min-width: breakpoint.$md) {
    gap: 20px;
    row-gap: 30px;
    margin-left: 0;
    margin-right: 0;
    grid-template-columns: repeat( auto-fit, minmax(450px, 1fr) );
  }
}

