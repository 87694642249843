@use 'src/styles/breakpoint';
@use 'src/styles/color';
@use 'src/styles/fontweight';

.header {
  padding: 0 0 10px;
  @media (min-width: breakpoint.$md) {
    padding: 0 0 20px;
  }
}

.link {
  cursor: pointer;
  display: inline-grid;
  align-items: center;
  grid-template-columns: auto auto;

  &:hover .arrow {
    transform: scale(1.3);
  }
}

.heading {
  font-size: 20px;
  margin: 0;
  font-weight: fontweight.$semi_bold;
  color: color.$black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (min-width: breakpoint.$md) {
    font-size: 30px;
  }
}

.arrow {
  height: 21px;
  margin-top: 6px;
  transition: transform .2s ease-in-out;
  @media (min-width: breakpoint.$md) {
    height: 32px;
    margin-top: 9px;
  }
}
