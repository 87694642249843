@use 'src/styles/color';

.closeButton {
  outline: none;
  cursor: pointer;

  :focus {
    outline: none;
  }

  border: none;
  width: 44px;
  height: 44px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: color.$white;
  border-radius: 100%;
  transition: transform 0.2s ease-out;

  :global(.no-touch) &:hover:not(:active) {
    transform: scale(1.1, 1.1);
  }

  &:active {
    transition: transform 0.1s ease-out;
    transform: scale(0.98, 0.98);
  }
}
