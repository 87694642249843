@use 'src/styles/breakpoint';
@use "src/styles/color";

@keyframes pulsate {
  from { opacity: 0.8; }
  50% { opacity: 0.2; }
  to { opacity: 0.8; }
}

.listContainer {
  container-type: inline-size;
  .list {
    display: grid;
    padding: 0;
    list-style-type: none;
    grid-template-columns: repeat(auto-fill, 100%);
    margin: 0 -20px 20px;
    li {
      padding: 0 20px;
    }
  }
  @container (max-width: 535px) {
    .list {
      margin: 0 0 20px;
      li {
        padding: 0;
        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
  @container (min-width: 536px) {
    .list {
      margin: 0;
      grid-template-columns: repeat( auto-fit, minmax(370px, 1fr) );
      li {
        padding: 0 10px 0 0;
        button {
          visibility: hidden;
        }
        &:hover {
          button {
            visibility: visible;
          }
        }
      }
    }
  }
}

.skeleton {
  opacity: 0.8;
  animation: pulsate 4s linear infinite;
}

.item {
  position: relative;
  padding: 0 20px 10px 20px;
  @media (min-width: breakpoint.$md) {
    padding-left: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.image {
  flex-shrink: 0;
  border-radius: 2px;
  overflow: hidden;
  width: 50px;
  height: 50px;
  border: 1px solid #eee;
  background: #f7f7f7;
}

.link {
  display: flex;
  flex-shrink: 0;
  padding: 10px 0;
  align-content: center;
  align-items: center;
  color: color.$black;
}

.title {
  flex-shrink: 1;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
