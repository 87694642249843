@use 'src/styles/color';

.followButton {
  background: white;
  border-radius: 50px;
  border: 1px solid color.$black;
  color: color.$black;
  padding: 0 10px 2px;
  font-size: 14px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    margin: 3px 2px 0 -3px;
  }
  &:hover {
    background-color: color.$black;
    color: color.$white;
    & > svg {
      fill: white;
    }
    fill: white;
  }
}

@keyframes grow {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

.followAnimation {
  animation: grow ease-in-out 0.4s;
}
