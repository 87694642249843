@use '/src/styles/fontweight';
@use '/src/styles/color';

.header {
  color: color.$white;
  padding: 12px 12px 12px 27px;
  background: #f6b043;
  display: grid;
  font-weight: fontweight.$semi_bold;
  font-size: 14px;
  grid-template-columns: auto max-content;
  line-height: 34px;

  span {
    font-size: 14px;
  }

  a,
  a:active,
  a:visited,
  a:hover {
    font-size: 12px;
    transition: background 0.3s ease-in-out;
    color: color.$white;
    display: grid;
    font-weight: fontweight.$medium;
    background: #f5a830;
    border-radius: 3px;
    padding: 0 15px;
    cursor: pointer;
  }

  a:hover {
    background: #e89b23;
    text-decoration: none;
  }
}
