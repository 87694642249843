@use 'src/styles/breakpoint';
@use 'src/styles/color';

.podcastListItem {
  list-style-type: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  border-bottom: 1px solid color.$grey-10;
  p {
    margin: 0;
    color: color.$black;
    font-size: 15px;
  }
}

.podcastLink {
  padding: 10px 0;
  display: flex;
  align-items: center;
  .podcastImage {
    transition: transform 0.2s ease-out;
  }
  :global(.no-touch) &:hover:not(:active) {
    @media (min-width: breakpoint.$md) {
      .podcastImage {
        transform: scale(1.05);
      }
    }
  }

  &:active {
    transition: transform 0.1s ease-out;
    transform: scale(0.98, 0.98);
  }
}

.podcastImage {
  border: 1px solid #eee;
  flex-shrink: 0;
  background-color: rgba(color.$black, 0.1);
  border-radius: 3px;
  margin-right: 10px;
  overflow: hidden;
  width: 48px;
  height: 48px;
}
