@use "src/styles/breakpoint";
@use "src/styles/fontweight";
@use "src/styles/color";


.link {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: #333;
  overflow: hidden;
  font-size: 16px;
  line-height: 16px;

  &:focus-within {
    outline: 2px solid #000000;
    outline-offset: 1px;
  }
}

.image {
  object-fit: cover;
  border-radius: 5px;
}

.heading {
  position: absolute;
  bottom: 10px;
  color: color.$white;
  padding: 0 10px;
  border-radius: 5px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 10%, rgba(0, 0, 0, 0) 70%);
  opacity: 0.5;
  transition: opacity 0.3s ease-out;
  border-radius: 5px;

  &:hover {
    opacity: 1.0;
  }
}



