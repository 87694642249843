@use "src/styles/fontweight";
@use "src/styles/color";

.select {
  appearance: none;
  background-color: color.$white;
  height: calc(1.5rem + 26px);
  border: 0;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: calc(3.25rem + 22px);
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDE0IDEwIj4KICA8cGF0aCBmaWxsPSIjMDAwIiBmaWxsLW9wYWNpdHk9Ii42IiBmaWxsLXJ1bGU9Im5vbnplcm8iIGQ9Ik0xMy4yIDIuMDdjLjU1LS43LjE2LTEuOS0uNi0xLjlIMS4wOGMtLjc3IDAtMS4xNiAxLjItLjYyIDEuOWw1Ljc2IDcuNDZjLjMzLjQ0Ljg4LjQ0IDEuMjIgMGw1Ljc1LTcuNDZ6Ii8+Cjwvc3ZnPgo="),
    url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMiIgaGVpZ2h0PSI1MCIgdmlld0JveD0iMCAwIDIgNTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxsaW5lIHgxPSIxIiB4Mj0iMSIgeTI9IjUwIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utb3BhY2l0eT0iMC4xIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==");
  background-size: 16px, 2px;
  background-position: calc(100% - 1.125rem) center, calc(100% - 3.25rem) center;
  background-repeat: no-repeat;
  font-weight: fontweight.$medium;
  width: 100%;
  color: rgba(color.$black, 0.3);

  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  &.fieldValue {
    font-weight: fontweight.$semi_bold;
    color: rgba(color.$black, 0.6);
  }

  &:focus {
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.1), 0 10px 20px rgba(0, 0, 0, 0.1);
    background-color: color.$white;
    border-color: color.$grey-5;
    outline: 0;
  }
}
