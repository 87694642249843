@use 'src/styles/breakpoint';
@use 'src/styles/color';
@use 'src/styles/fontweight';

.container {
  display: flex;
  min-width: 0;
  padding: 0 20px 0;
  @media (min-width: breakpoint.$md) {
    padding: 0;
  }
}

.container:not(:last-child) {
  padding: 0 20px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  @media (min-width: breakpoint.$md) {
    border-bottom: none;
    padding: 0;
  }
}

.image {
  transition: box-shadow 0.3s ease-out;
  background: #f7f7f7;
  border: 1px solid #eee;
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 3px;
  @media (min-width: breakpoint.$md) {
    width: 110px;
    height: 110px;
  }
}

.textContent {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  font-weight: fontweight.$medium;
  font-size: 0.75rem;
  line-height: 180%;
  padding: 0 10px;
  color: color.$black;

  @media (min-width: breakpoint.$md) {
    font-size: 1rem;
    padding: 0 20px;
  }

  h2 {
    max-width: 100%;
    font-weight: fontweight.$semi_bold;
    min-width: 0;
    font-size: 1rem;
    margin-bottom: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (min-width: breakpoint.$md) {
      margin-bottom: 10px;
      font-size: 20px;
    }
  }
}

.description {
  color: rgba(color.$black, 0.65);
  word-break: break-word;
  hyphens: auto;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0;
  line-height: 1.5em;

  @media (min-width: breakpoint.$md) {
    -webkit-line-clamp: 3;
  }
}

.number {
  color: color.$accent-100;
  margin-right: 0.3125rem;

  @media (min-width: breakpoint.$md) {
    margin-right: 0.625rem;
  }
}

.link {
  width: 100%;
  text-decoration: none !important;
  cursor: pointer;

  & > div {
    display: flex;
  }
}
