@use 'src/styles/breakpoint';
@use 'src/styles/color';
@use 'src/styles/fontweight';

.footer {
  position: relative;
  background-color: color.$black;
  padding: 60px 20px 60px;
  @media (min-width: breakpoint.$md) {
    padding: 60px 60px 60px;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.lists {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 100%;
  @media (min-width: breakpoint.$lg) {
    width: auto;
    margin-bottom: 30px;
    justify-content: center;
  }
  @media (min-width: breakpoint.$xl) {
    justify-content: start;
  }
}

.end {
  grid-column: auto;
  padding-top: 30px;
  border-top: 1px solid #242424
}

.leftLogo {
  float: left;
  margin-right: 20px;
  opacity: 50%;
  transition: opacity 0.5s;
  &:hover {
    opacity: 100%;
  }
}

.rightLogo {
  float: right;
  svg {
    vertical-align: bottom;
  }
}

.list {
  display: block;
  text-align: center;
  width: 100%;
  margin-bottom: 60px;
  flex-grow: 1;
  box-sizing: border-box;
  justify-content: space-between;

  @media (min-width: breakpoint.$md) {
    width: 20%;
    text-align: left;
    margin-bottom: 30px;
  }

  @media (min-width: breakpoint.$xl) {
    text-align: left;
    max-width: 150px;
  }

  h2 {
    font-size: 13px;
    font-weight: fontweight.$medium;
    color: color.$grey-60;
    margin-bottom: 0;
    @media (min-width: breakpoint.$md) {
      margin-bottom: 10px;
    }
    @media (min-width: breakpoint.$xl) {
      margin-bottom: 20px;
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 13px;
    li {
      padding: 4px 4px 4px 0;
      overflow-wrap: break-word;
      word-break: break-word;
      hyphens: auto;
      button, a {
        color: color.$white;
        background: transparent;
        border: none;
        padding: 0;
        cursor: pointer;
      }
    }
  }
}

.download {
  width: 100%;
  text-align: center;
  border-top: 1px solid #242424;
  @media (min-width: breakpoint.$xl) {
    width: auto;
    margin-left: auto;
    border: none;
  }
}

.store {
  display: inline-block;
  filter: grayscale(1);
  margin: 30px 15px;

  @media (min-width: breakpoint.$xl) {
    display: block;
    margin: 0 0 15px 0;
  }

}

.buttonLink {
  background: transparent;
  color: color.$white;
  border: none;
  padding: 0;
  text-align: left;
  cursor: pointer;
}
