@use 'src/styles/breakpoint';
@use 'src/styles/color';
@use 'src/styles/fontweight';

.faq {
  background: #111;
  color: color.$white;
  margin: 0;
  padding: 60px 20px 30px;
  @media (min-width: breakpoint.$md) {
    padding: 60px 60px 30px;
  }
}

.heading {
  font-size: 16px;
  color: color.$grey-40;
  border-bottom: 1px solid #222;
  padding-bottom: 30px;
  margin-bottom: 0;
}

.questions {
  margin: 0;
  padding: 0;
  display: grid;
  @media (min-width: breakpoint.$xxl) {
    gap: 120px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    border-bottom: 1px solid #222;
    &:last-child {
      border-bottom: none;
    }
  }
}

.row {
  border-bottom: 1px solid #222;
  padding: 30px 0 14px;
  align-content: flex-start;
  &:last-child {
    border-bottom: none;
  }
  @media (min-width: breakpoint.$md) {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  @media (min-width: breakpoint.$xxl) {
    border-bottom: none;
  }
}

.question {
  color: color.$white;
  margin-bottom: 10px;
  @media (min-width: breakpoint.$md) {
    margin-bottom: 20px;
    grid-column: span 4 / span 4;
  }
  @media (min-width: breakpoint.$lg) {
    grid-column: span 3 / span 3;
  }
  @media (min-width: breakpoint.$xl) {
    grid-column: span 4 / span 4;
  }
  @media (min-width: breakpoint.$xxl) {
    grid-column: span 12 / span 12;
  }
}

.answer {
  padding: 0;
  margin: 0;
  color: color.$grey-40;
  @media (min-width: breakpoint.$md) {
    grid-column: span 8 / span 8;
  }
  @media (min-width: breakpoint.$lg) {
    grid-column: span 9 / span 9;
  }
  @media (min-width: breakpoint.$xl) {
    grid-column: span 8 / span 8;
  }
  @media (min-width: breakpoint.$xxl) {
    grid-column: span 12 / span 12;
  }

  a {
    color: color.$white;
    text-decoration: underline;
  }
}
