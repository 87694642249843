@use "src/styles/color";
@use "src/styles/breakpoint";
@use "src/styles/fontweight";

.marketSelector {
  width: 100%;
  background-color: color.$grey-10;
  -webkit-overflow-scrolling: touch;
}
.container {
  width: 100%;
  padding: 20px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  @media (min-width: breakpoint.$md) {
    padding: 25px;
    max-width: 1140px;
  }
}
.selectWrapper {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  @media (min-width: breakpoint.$md) {
    justify-content: flex-start;
    width: 75%;
    align-items: center;
    flex-direction: row;
  }
}
.selectLabel {
  margin-right: 1rem;
  width: 100%;
  @media (min-width: breakpoint.$md) {
    width: unset;
    margin-right: 2rem;
  }
}
.selectContainer {
  flex-direction: row;
  display: flex;
  width: 100%;
  margin-right: 1rem;
  @media (min-width: breakpoint.$md) {
    width: 300px;
  }
}

.buttonContainer {
  width: 30%;
  padding-left: 1rem;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: flex-end;
  @media (min-width: breakpoint.$md) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 25%;
    padding-left: 2rem;
  }
  .button {
    width: 100%;
    @media (min-width: breakpoint.$md) {
      width: unset;
    }
  }
  .closeButton {
    margin-bottom: 0.5rem;
    background-color: transparent;
    @media (min-width: breakpoint.$md) {
      margin-bottom: 0;
      margin-left: 2rem;
    }
  }
}
