@use 'src/styles/color';
@use 'src/styles/breakpoint';

@keyframes loading {
  0% {
    background-position: -500px center;
  }
}

.button {
  background: transparent;
  outline: none;

  &:focus {
    outline: none;
  }

  border: none;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding: 0.875rem 2rem;

  &.color-primary {
    color: color.$accent-100;
    box-shadow: inset 0 0 0 2px color.$accent-100;
  }

  &.color-dark-blue {
    color: color.$blue-100;
    box-shadow: inset 0 0 0 2px color.$blue-100;
  }


  &.color-light {
    color: color.$white;
    box-shadow: inset 0 0 0 2px color.$white;
  }

  &.color-dark {
    color: color.$black;
    box-shadow: inset 0 0 0 2px color.$black;
  }


  font-size: 1rem;
  text-align: center;
  @media (min-width: breakpoint.$md) {
    font-size: 1.25rem;
    padding: 0.75rem 2.8125rem;
  }
  border: 0;

  border-radius: 100px;

  // Animation
  transition: transform 0.5s, box-shadow 0.5s;

  // States
  &:after {
    content: "";
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
  }

  &:hover:not([disabled]) {
    text-decoration: none;
  }

  :global(.no-touch) &:hover:not([disabled]) {
    text-decoration: none;
    transition: 0.2s;

    &:after {
      opacity: 0;
      transition: 0.2s;
    }
  }

  &:active:not([disabled]) {
    transition: 0.05s;
    transform: translateY(0px) scale(1.01);
  }

  :global(.no-touch) &:focus {
    outline: 0;
  }

  :global(.no-touch) &:focus {
    &.primary {
      &.backgroundContextDarkBlue {
        &:after {
          box-shadow: 0 0 0 2px color.$blue-100, color.$accent-100 0 0 0 6px;
          display: block;
        }
      }

      &.backgroundContextLight {
        &:after {
          box-shadow: 0 0 0 2px color.$white, color.$accent-100 0 0 0 6px;
          display: block;
        }
      }

      &.backgroundContextDark {
        &:after {
          box-shadow: 0 0 0 2px color.$black, color.$accent-100 0 0 0 6px;
          display: block;
        }
      }
    }

    &.backgroundContextDarkBlue {
      &.color-primary {
        &:after {
          box-shadow: 0 0 0 2px color.$blue-100,
            rgba(color.$accent-100, 0.3) 0 0 0 6px;
          display: block;
        }
      }

      &.color-dark-blue {
        &:after {
          box-shadow: 0 0 0 2px color.$blue-100,
            rgba(color.$blue-100, 0.3) 0 0 0 6px;
          display: block;
        }
      }

      &.color-light {
        &:after {
          box-shadow: 0 0 0 2px color.$blue-100,
            rgba(color.$white, 0.3) 0 0 0 6px;
          display: block;
        }
      }

      &.color-dark {
        &:after {
          box-shadow: 0 0 0 2px color.$blue-100,
            rgba(color.$black, 0.3) 0 0 0 6px;
          display: block;
        }
      }
    }

    &.backgroundContextLight {
      &.color-primary {
        &:after {
          box-shadow: 0 0 0 2px color.$white,
            rgba(color.$accent-100, 0.3) 0 0 0 6px;
          display: block;
        }
      }

      &.color-dark-blue {
        &:after {
          box-shadow: 0 0 0 2px color.$white,
            rgba(color.$blue-100, 0.3) 0 0 0 6px;
          display: block;
        }
      }

      &.color-light {
        &:after {
          box-shadow: 0 0 0 2px color.$white,
            rgba(color.$white, 0.3) 0 0 0 6px;
          display: block;
        }
      }

      &.color-dark {
        &:after {
          box-shadow: 0 0 0 2px color.$white, rgba(color.$black, 0.3) 0 0 0 6px;
          display: block;
        }
      }
    }

    &.backgroundContextDark {
      &.color-primary {
        &:after {
          box-shadow: 0 0 0 2px color.$black,
            rgba(color.$accent-100, 0.3) 0 0 0 6px;
          display: block;
        }
      }

      &.color-dark-blue {
        &:after {
          box-shadow: 0 0 0 2px color.$black,
            rgba(color.$blue-100, 0.3) 0 0 0 6px;
          display: block;
        }
      }

      &.color-light {
        &:after {
          box-shadow: 0 0 0 2px color.$black, rgba(color.$white, 0.3) 0 0 0 6px;
          display: block;
        }
      }

      &.color-dark {
        &:after {
          box-shadow: 0 0 0 2px color.$black, rgba(color.$black, 0.3) 0 0 0 6px;
          display: block;
        }
      }

    }
  }

  &.primary {
    color: color.$white !important; /* !important so that color is not overridden when component is used as Link */
    background-color: color.$accent-100;
    // Focus border
    box-shadow: inset 0 0 0 2px color.$accent-100;

    &:disabled {
      box-shadow: color.$accent-100 0 0 0 2px;
    }

    &.isLoading {
      background-image: linear-gradient(
        -60deg,
        #ff5b65 0%,
        #c23 50%,
        #ff5b65 100%
      );
      background-size: 500px 1px;
      background-position: 0 center;
      animation: loading 1s linear infinite;
    }
  }

  &.isLoading {
    opacity: 0.5;
    box-shadow: none !important;
    transform: none;
    transition: opacity 1s 0.2s, box-shadow 0s;
    cursor: wait !important;

    &:hover {
      box-shadow: none;
    }
  }

  &:disabled {
    opacity: 0.5;
  }
}
