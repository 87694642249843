@use 'src/styles/breakpoint';
@use 'src/styles/color';
@use 'src/styles/fontweight';

.block {
  padding: 10px 20px 20px;
  @media (min-width: breakpoint.$md) {
    padding: 0 60px 60px;
  }
}

.blocks {
  padding-top: 20px;
  @media (min-width: breakpoint.$md) {
    padding-top: 60px;
  }
}
