@use 'src/styles/breakpoint';
@use 'src/styles/color';
@use 'src/styles/fontweight';

.header {
  display: grid;
  grid-template-columns: 1fr max-content;
  width: 100%;
  align-items: center;
}

.heading {
  font-size: 20px;
  margin: 0;
  padding: 0 0 10px;
  cursor: pointer;
  font-weight: fontweight.$semi_bold;
  color: color.$black;
  @media (min-width: breakpoint.$md) {
    font-size: 30px;
    line-height: 40px;
  }
}

.arrow {
  height: 16px;
  @media (min-width: breakpoint.$md) {
    height: 30px;
  }
}

.listContainer {
  margin: -5px -20px;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (min-width: breakpoint.$md) {
    margin: -5px -60px -25px;
    &::-webkit-scrollbar {
      display: block;
      height: 20px;
    }
    &::-webkit-scrollbar-thumb {
      border-style: solid;
      border-color: color.$white;
      border-width: 8px 63px;
      background: rgba(0, 0, 0, .10);
    }
    &:not(:hover)::-webkit-scrollbar-thumb {
      background: transparent;
    }
  }
}

.list {
  display: inline-flex;
  padding: 5px 20px 5px 0;
  margin: 0;
  gap: 10px;
  @media (min-width: breakpoint.$md) {
    gap: 20px;
    padding: 5px 60px 5px 0;
  }
}

.listItem {
  position: relative;
  height: 110px;
  width: 110px;
  list-style-type: none;
  background: #f7f7f7;
  border-radius: 5px;
  border: 1px solid #eee;
  overflow: hidden;
  @media (min-width: breakpoint.$sm) {
    height: 120px;
    width: 120px;
  }
  @media (min-width: breakpoint.$md) {
    height: 140px;
    width: 140px;
  }
  @media (min-width: breakpoint.$lg) {
    height: 160px;
    width: 160px;
  }
  &:focus-within {
    outline: 2px solid #000000;
    outline-offset: 1px;
  }

  transition: transform 0.2s ease-out;
  :global(.no-touch) &:hover:not(:active) {
    @media (min-width: breakpoint.$md) {
      transform: scale(1.05);
    }
  }
  &:active {
    transition: transform 0.1s ease-out;
    transform: scale(0.98, 0.98);
  }
}

.listItem:first-child {
  margin-left: 20px;
  @media (min-width: breakpoint.$md) {
    margin-left: 60px;
  }
}

.title {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: color.$black;
  font-size: 13px;
  @media (min-width: breakpoint.$md) {
    font-size: 16px;
  }
}

.image{
  width: 100%;
  height: 100%;
}
