@use "src/styles/breakpoint";
@use "src/styles/fontweight";
@use "src/styles/color";

.container {
  margin: -5px -20px;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (min-width: breakpoint.$md) {
    margin: 0;
    overflow: unset;
  }
}

.list {
  gap: 10px;
  padding: 5px 20px 5px 0;
  display: inline-grid;
  grid-template-columns: repeat(auto-fill, 143px);
  grid-template-rows: 81px 81px 81px;
  grid-auto-columns: minmax(143px, auto);
  grid-auto-flow: column;
  margin: 0 0 0 20px;
  @media (min-width: breakpoint.$md) {
    margin: 0;
    display: grid;
    padding: 0;
    grid-auto-rows: 154px;
    grid-template-columns: repeat( auto-fit, minmax(240px, 1fr) );
    grid-template-rows: unset;
    grid-auto-columns: unset;
    grid-auto-flow: unset;
  }
  li {
    list-style-type: none;
  }
}
