@use 'src/styles/breakpoint';
@use 'src/styles/color';

.header {
  position: relative;
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-rows: min-content min-content;
  background: #000;
  @media (min-width: breakpoint.$md) {
    grid-template-columns: min-content 1fr min-content;
    padding-right: 60px;
  }

  a {
    cursor: pointer;
  }

}

.logo {
  padding: 20px 10px 10px 20px;
  display: flex;
  align-items: center;

  @media (min-width: breakpoint.$md) {
    padding: 20px 20px 20px 60px;
  }
}

.dumbHeaderLogo {
  padding: 20px 0 20px 20px;
  display: flex;
  align-items: center;

  @media (min-width: breakpoint.$md) {
    padding: 20px 0 20px 60px;
  }
}

.mainMenuWrapper {
  position: relative;
  grid-column: 1 / -1;
  width: 100%;
  overflow: hidden;

  @media (min-width: breakpoint.$md) {
    grid-column: 2 / 3;
    grid-row: 1;
    border-top: none;
    background: transparent;
  }
}

.mainMenu {
  display: flex;
  list-style-type: none;
  padding: 0 30px 0 10px;
  margin: 0;
  height: 100%;
  overflow: scroll;

  @media (min-width: breakpoint.$md) {
    padding: 0 30px 0 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    content: "";
    height: 100%;
    width: 30px;
    pointer-events: none;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  }

  li {
    display: inline-block;
    height: 100%;
    color: color.$grey-10;

    a, button {
      white-space: nowrap;
      color: inherit;
      background: transparent;
      border: none;
      cursor: pointer;
      font-size: 13px;
      display: flex;
      align-items: center;
      height: 100%;
      padding: 6px 10px;

      border-bottom: 2px solid color.$black;

      @media (min-width: breakpoint.$xl) {
        padding: 0 15px;
      }
    }
  }

}

.megaMenuIsActive {
  background-color: color.$grey-100 !important;
  border-bottom-color: color.$grey-100 !important;
}


.iconMenu {
  display: flex;
  justify-content: flex-end;
  list-style-type: none;
  padding: 0;
  margin: 5px 10px 5px 0;
  height: 100%;

  @media (min-width: breakpoint.$md) {
    margin-top: 0;
  }


  li {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;

    button {
      cursor: pointer;
      border: none;
      display: flex;
      margin: 0;
      align-items: center;
      justify-content: center;
      // Prevent children (icons) to shrink on Mobile Safari 16.2.
      & > * {
        flex-shrink: 0;
      }

      height: 30px;
      min-width: 30px;
      border-radius: 999px;
      font-size: 13px;
      line-height: 13px;
      color: color.$white;
      background: #242424;
      text-align: center;
      white-space: nowrap;

      &:hover {
        background: #333;
      }

      &.loginButton {
        padding: 0 12px;
        span {
          margin-top: -2px;
        }
      }

      &.profileButton {
        font-size: 15px;
        span {
          margin-top: -2px;
          white-space: nowrap;
        }
      }

    }
  }

}

.mainMenu a.active {
  color: color.$white !important;
  border-bottom-color: color.$accent-100;
}

.collectionLinkMobile {
  @media (min-width: breakpoint.$md) {
    display: none !important;
  }
}

.collectionLinkDesktop {
  display: none !important;
  @media (min-width: breakpoint.$md) {
    display: flex !important;
  }
}




